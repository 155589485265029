.incoming_msg_img {
    float: left;
    display: inline-block;
}
.received_msg {
    margin-top: 0;
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
}
.received_withd_msg p {
    background: #4696e5 none repeat scroll 0 0;
    border-radius: 5px;
    color:#fff;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
}
.time_date_sent {
    color: grey;
    display: block;
    font-size: 12px;
    margin: 0;
}
.time_date_received {
    color: lightgrey;
    display: block;
    font-size: 12px;
    margin: 0;
}
.received_withd_msg { width: 70%;}
.mesgs {
    float: left;
    padding: 30px 15px 0 25px;
    width: 60%;
}
.sent_msg p {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 5px;
    font-size: 14px;
    margin: 0;
    color: #646464;
    padding: 5px 10px 5px 12px;
    width:100%;
}
.outgoing_msg{
    overflow:hidden;
    margin: 26px 0 0;
}
.sent_msg {
    float: right;
    width: 70%;
}
.chat{
    margin: 10px auto;
    width: 50%;
    padding: 0;
}
@media (max-width: 780px) {
    .chat {
        width: 80%;
    }
}
@media (max-width: 500px) {
    .chat {
        width: 90%;
    }
}