.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.App-title {
  font-size: 1.5em;
}
.App-intro {
  font-size: large;
}
.content {
  position: relative;
  top: 1px;
}
.alert-danger{
  background-color: #f2dede;
  border-color: #ea2f10;
  color: #a94442;
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.google-btn {
  border: none;
  background: #dd4b39;
}

.google-btn :hover {
  color: #f2dede
}

.facebook-btn {
  border: none;
  background: #3b5998;
}

.facebook-btn :hover {
  color: #f2dede
}

.active {
  color: #4696e5
}
.left-align{
  text-align: left;
}
.noselect{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
#topcontrol {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  background: #4696e5;
  color: #fff;
  text-align: center;
  display: inline-block;
  z-index: 30;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}
#topcontrol:hover {
  background: #3878b7;
  color: #fff;
}
#topcontrol .fa {
  position: relative;
  top: 3px;
  font-size: 25px;
  font-weight: normal;
}
.navbar-brand{
  padding-left: 5px;
  font-size: inherit;
}
.navbar-brand-color{
  color: rgba(0, 0, 0, 0.54);
}
.modal-dialog {
  margin: 100px auto;
}
button {
  box-shadow: none !important;
}
span.icon-bar {
  background-color: grey;
}
.card-container{
  position: relative;
  right: 0;
  left: 0;
  margin: 10px auto;
  min-height: 10em;
  width: 90%;
  /* just for example presentation */
}
span[class*="MuiCardHeader-title-"] {
  text-align: left;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: grey;
}
.grid-card{
  min-width: 280px;
  max-width: 280px;
  margin: 15px;
}
.grid-media{
  margin: 0 auto;
  width: 300px;
  height: 450px;
  padding-top: 100%;
}
.media{
  margin: 0 auto;
  width: 200px;
  height: 285px;
}
.margin-10{
  margin: 10px;
}
.MuiButton-containedPrimary-13 {
  background-color: #4696e5 !important;
}
.reader{
  width: 60%;
  margin: 0 auto;
  color: #5b5b5b;
}
@media (max-width: 500px) {
  .card-container{
    margin: 0;
    clear:both;
    width: 100%;
  }
  .card{
    margin-left: 0;
    margin-top: 15px;
  }
  .content {
    top: 1px;
    padding-bottom: 60px;
  }
  .reader{
    width: 100%;
    margin: 0 auto;
  }
}
input[type="text"], input[type="password"], input[type="email"], select, textarea {
  color: grey;
  border: none;
  box-shadow: none;
  background-color: inherit;
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, select:focus, textarea:focus {
  color: inherit;
  border: none;
  box-shadow: none;
}
.emoji-picker .emoji {
  height: 40px;
  width: 40px;
  margin: 2px;
  background-size: 100%;
}